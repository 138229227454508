import Swiper from "swiper";
import { Scrollbar } from "swiper/modules";
import 'swiper/css';
import "swiper/css/scrollbar";

const swiperClass = "nzp-gallery-swiper__swiper";
const swiperElement = document.querySelector(`.${swiperClass}`);
if (swiperElement) {
  new Swiper(swiperElement, {
    slidesPerView: 1.25,
    spaceBetween: 32,
    centeredSlides: true,
    initialSlide: 1,
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
      dragSize: "32px"
    },
    breakpoints: {
      768: {
        slidesPerView: 2.5,
      }
    },
    modules: [Scrollbar]
  });
}
