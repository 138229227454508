const formWrappers = document.querySelectorAll('.nzp-contact-form-block__form-wrapper ');
const select = document.querySelector(".nzp-contact-form-block__select");

if (select) {
  select.addEventListener('change', (e) => onChangeHandler(e, formWrappers));
}

/**
 * Handles the change of the select
 */
const onChangeHandler = (e, forms) => {
  let option = e.target.options[e.target.selectedIndex];

  // Get the value of the selected option
  let value = option.value

  // Hide form
  toggleForms(forms, value);
}

/**
 * Make current form invisible and make new form visible.
 */
const toggleForms = (forms, id) => {

  Array.from(forms).forEach((form) => {
    // Hide current visible form
    if (!form.classList.contains('tw-hidden')) {
      form.classList.add('tw-hidden');
    }

    // Show new selected form
    if (form.dataset.formId == id) {
      form.classList.remove('tw-hidden');
    }
  })
}
