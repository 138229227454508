import Swiper from "swiper";
import { Scrollbar } from "swiper/modules";
import 'swiper/css';
import "swiper/css/scrollbar";

const swiperClass = "nzp-logo-swiper__swiper";
const swiperElement = document.querySelector(`.${swiperClass}`);

if (swiperElement) {
  new Swiper(swiperElement, {
    slidesPerView: "auto",
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
      dragSize: "32px"
    },
    modules: [Scrollbar]
  });
}
